<template>
  <div>
    <moHeader></moHeader>
    <router-view></router-view>
    <moFooter></moFooter>
  </div>
</template>

<script>
import moHeader from "@/components/moHeader";
import moFooter from "@/components/moFooter";
export default {
  name: "nav-home",
  components:{
      moHeader,
      moFooter
  }

}
</script>

<style>

</style>
