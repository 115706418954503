<template>
  <div>
    <!--  banner -->
    <div class="block">
      <el-carousel height="700px">
        <el-carousel-item v-for="item in list" :key="item.id">
          <div class="postion">
            <h1>{{ item.title }}</h1>
            <div class="postion-item" style="color:#fff;">{{ item.content }}</div>
            <router-link to="/register">
              <div class="per">免费试用</div>
            </router-link>
          </div>
          <img
              :src="item.image"
              alt=""
              style="width: 100%; height: 100% no-repeat"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- product -->
    <div class="product">
      <div class="product-top">
        <div class="product-title">
          核心产品
        </div>
        <div class="product-title2">
          匠心打造，数百次产品迭代升级
        </div>
      </div>
      <div class="product-category">
        <div class="category-item">
          <div class="category-img">
            <img src="../../public/shou2.png" alt="">
          </div>
          <div class="category-title">
            售后管理系统
          </div>
        </div>
        <div class="category-item">
          <div class="category-img">
            <img src="../../public/ke2.png" alt="">
          </div>
          <div class="category-title">
            服务云
          </div>
        </div>
        <div class="category-item">
          <div class="category-img">
            <img src="../../public/yi2.png" alt="">
          </div>
          <div class="category-title">
            APP 小程序
          </div>
        </div>
      </div>
    </div>
    <div class="products">
      <div class="product-content">
        <div class="content-item">
          <div class="item-img">
            <img src="../../public/ke_09.jpg" alt="">
          </div>
          <div class="item-content">
            <div class="item-title">
              工单管理
            </div>
            <div class="item-contents">
              多渠道客户报修、快速受理、智能派工、高效处 <br/>理客户的售后请求
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-img">
            <img src="../../public/shou_05.jpg" alt="">
          </div>
          <div class="item-content">
            <div class="item-title">
              备件管理
            </div>
            <div class="item-contents">
              备件库存管理，备件出入库管理，员工领料退 <br> 料，服务商申请备件
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-img">
            <img src="../../public/shou_09.jpg" alt="">
          </div>
          <div class="item-content">
            <div class="item-title">
              结算管理
            </div>
            <div class="item-contents">
              售后服务费用自动结算，大幅提升工作效率，降 <br>低人员成本
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-img">
            <img src="../../public/shou_10.jpg" alt="">
          </div>
          <div class="item-content">
            <div class="item-title">
              客户管理
            </div>
            <div class="item-contents">
              客户档案管理，设备档案管理，客户合同管理，<br>服务计划管理，服务提醒管理
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-img">
            <img src="../../public/shou_13.jpg" alt="">
          </div>
          <div class="item-content">
            <div class="item-title">
              服务商管理
            </div>
            <div class="item-contents">
              企业可邀请服务商加入服务空间，轻松管理遍布 <br>全国的售后服务网络
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-img">
            <img src="../../public/shou_14.jpg" alt="">
          </div>
          <div class="item-content">
            <div class="item-title">
              外勤管理
            </div>
            <div class="item-contents">
              实时查看服务人员的当前位置、行程轨迹，彻底 <br>解决外勤人员的管理难题
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-img">
            <img src="../../public/shou_17.jpg" alt="">
          </div>
          <div class="item-content">
            <div class="item-title">
              统计分析
            </div>
            <div class="item-contents">
              多维度数据挖掘分析，为企业进行绩效考核，提 <br>升服务品质提供数据支持
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-img">
            <img src="../../public/shou_18.jpg" alt="">
          </div>
          <div class="item-content">
            <div class="item-title">
              服务监控
            </div>
            <div class="item-contents">
              企业售后服务过程实时监控，破解售后服务的监 <br>管难题，真正提升客户满意度
            </div>
          </div>
        </div>

      </div>
      <router-link to="/register">
        <div class="product-button">了解更多</div>
      </router-link>
    </div>
    <div>
      <div class="fuwu">
        <div class="solution">
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg1.png" alt="">
            </div>
            <div class="itemTitle">
              机电设备
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg3.png" alt="">
            </div>
            <div class="itemTitle">
              医疗器械
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg8.png" alt="">
            </div>
            <div class="itemTitle">
              家用电器
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg4.png" alt="">
            </div>
            <div class="itemTitle">
              仪器仪表
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg5.png" alt="">
            </div>
            <div class="itemTitle">
              智能制造
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg13.png" alt="">
            </div>
            <div class="itemTitle">
              汽车车辆
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg7.png" alt="">
            </div>
            <div class="itemTitle">
              IT运维
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg6.png" alt="">
            </div>
            <div class="itemTitle">
              安防监控
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg10.png" alt="">
            </div>
            <div class="itemTitle">
              净水设备
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg11.png" alt="">
            </div>
            <div class="itemTitle">
              手机数码
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg9.png" alt="">
            </div>
            <div class="itemTitle">
              家装建材
            </div>
          </div>
          <div class="solution-item">
            <div class="itemImg">
              <img src="../../public/animg2.png" alt="">
            </div>
            <div class="itemTitle">
              生活服务
            </div>
          </div>
        </div>
        <div class="banner">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="原厂售后" name="first">
              <div class="afterSales">
                <div class="afterSalesImg">
                  <img src="../../public/sales1.png" alt="">
                </div>
                <div class="afterSales-bottom">
                  <div class="content">
                    <div class="content-item">
                      原厂售后
                    </div>
                    <router-link to="/register">
                      <div class="liaojie">
                        了解更多+
                      </div>
                    </router-link>
                  </div>
                  <div class="contents">
                    适用于厂商直接为客户提供售后服务的管理场景。
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="外包售后" name="second">
              <div class="afterSales">
                <div class="afterSalesImg">
                  <img src="../../public/sales2.png" alt="">
                </div>
                <div class="afterSales-bottom">
                  <div class="content">
                    <div class="content-item">
                      外包售后
                    </div>
                    <router-link to="/register">
                      <div class="liaojie">
                        了解更多+
                      </div>
                    </router-link>
                  </div>
                  <div class="contents">
                    适用于厂商将产品售后服务全部（或部分）外包给第三方服务商的管理场景。
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="服务商售后" name="third">
              <div class="afterSales">
                <div class="afterSalesImg">
                  <img src="../../public/sales3.png" alt="">
                </div>
                <div class="afterSales-bottom">
                  <div class="content">
                    <div class="content-item">
                      服务商售后
                    </div>
                    <router-link to="/register">
                      <div class="liaojie">
                        了解更多+
                      </div>
                    </router-link>
                  </div>
                  <div class="contents">
                    适用于专业服务商、产品代理商、产品经销商，售后安装、维修、保养等场景。
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="内部维保" name="fourth">
              <div class="afterSales">
                <div class="afterSalesImg">
                  <img src="../../public/sales4.png" alt="">
                </div>
                <div class="afterSales-bottom">
                  <div class="content">
                    <div class="content-item">
                      内部维保
                    </div>
                    <router-link to="/register">
                      <div class="liaojie">
                        了解更多+
                      </div>
                    </router-link>
                  </div>
                  <div class="contents">
                    适用于银行、医院、政府、企业、酒店等单位内部设备维保管理场景。
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="ad" style="no-repeat;">
      <div class="title">
        <h2>我们的优势</h2>
        <p>专注做产品，用心做服务</p>
      </div>
      <div class="advantage">
        <div class="advantage-item">
          <div class="advantage-img">
            <img src="../../public/023_41.png" alt="">
          </div>
          <div class="advantage-title">
            专业品质
          </div>
          <div class="advantage-content">
            <div class="content">
              逾万家企业千锤百炼
              产品品质自然不凡
            </div>
          </div>
        </div>
        <div class="advantage-item">
          <div class="advantage-img">
            <img src="../../public/023_44.png" alt="">
          </div>
          <div class="advantage-title">
            方案灵活
          </div>
          <div class="advantage-content">
            <div class="content">
              提供灵活部署方案
              支持公有云、私有云部署
            </div>
          </div>
        </div>
        <div class="advantage-item">
          <div class="advantage-img">
            <img src="../../public/023_38.png" alt="">
          </div>
          <div class="advantage-title">
            数据安全
          </div>
          <div class="advantage-content">
            <div class="content">
              5重数据安全保障
              确保您的业务数据安全
            </div>
          </div>
        </div>
        <div class="advantage-item">
          <div class="advantage-img">
            <img src="../../public/023_47.png" alt="">
          </div>
          <div class="advantage-title">
            稳定可靠
          </div>
          <div class="advantage-content">
            <div class="content">
              分布式架构，服务器集群
              系统稳定不掉线
            </div>
          </div>
        </div>
        <div class="advantage-item">
          <div class="advantage-img">
            <img src="../../public/023_49.png" alt="">
          </div>
          <div class="advantage-title">
            贴心服务
          </div>
          <div class="advantage-content">
            <div class="content">
              专属顾问，专家支持
              7*24小时运维
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ca">
      <div class="title">
        10000+家企业正在使用
      </div>
      <div class="case">
        <div class="case-item">
          <img src="../../public/clients/bacl1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/cysy1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/djf1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/gwfc1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/hk1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/hp1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/ltzn1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/lxkx1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/mzlj1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/sywl1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/tpyy1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/wazdh1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/xkwl1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/ynsc1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/yrfl1.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/fxlh.png" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/qbzn.jpg" alt="">
        </div>
        <div class="case-item">
          <img src="../../public/clients/evash.png" alt="">
        </div>
      </div>
    </div>
    <div class="new">
      <div class="title">提升服务效率，助力企业价值成长</div>
      <div class="title-content">服务即营销</div>
      <!--      <div class="title-button" to="/register">
              立即免费体验
            </div> -->
      <router-link to="/register">
        <div class="title-button">立即免费试用</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      activeName: 'second',
      list: [
        {
          id: 1,
          image: 'https://kbbcloud-com.oss-cn-shenzhen.aliyuncs.com/home/banner/banner1.jpg',
          title: '新一代售后服务管理系统',
          content: '用最新的互联网技术、物联网技术为企业售后管理赋能'
        },
        {
          id: 2,
          image: 'https://kbbcloud-com.oss-cn-shenzhen.aliyuncs.com/home/banner/banner5.jpg',
          title: '客宝宝服务云平台',
          content: '随时随地掌控您的客户服务，售后管理如此简单'
        },
        {
          id: 3,
          image: 'https://kbbcloud-com.oss-cn-shenzhen.aliyuncs.com/home/banner/banner6.jpg',
          title: '移动服务APP',
          content: '实现企业与客户的无缝连接，让客户感受到科技的温度'
        }
      ],
    }
  },
  mounted() {
    console.log("download", Boolean(this._isMobile()));
    if (this._isMobile()) {
      // 为true是移动端,跳至移动端页面
      console.log("yi移动端");
      this.$router.replace('/mobileIndex')
    } else {
      // 为false是pc端，跳至pc端页面
      console.log("PC端");
      this.$router.replace('/index')
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 判断是什么设备
    _isMobile() {
      let plat = navigator.userAgent.match( // 判断不同端
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return plat;
    }
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none
}

h1 {
  // background-color:#678;
  color: #fff;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.product {
  padding-top: 70px;

  .product-top {
    .product-title {
      font-size: 32px;
      line-height: 32px;
      text-align: center;
    }

    .product-title2 {
      font-size: 16px;
      color: #00557f;
      text-align: center;
      margin-top: 15px;
    }
  }

  .product-category {
    display: flex;
    justify-content: space-evenly;
    overflow: hidden;
    text-align: center;
    margin-top: 60px;
  }
}

.products {
  background: #F5F6F7;
  padding-bottom: 50px;

  .product-content {
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 50px;


    .content-item {
      display: flex;
      justify-content: center;
      width: 34%;
      height: 120px;

      .item-content {
        .item-title {
          font-size: 18px;
          font-weight: bold;
          text-align: left;
        }

        .item-contents {
          font-size: 16px;
          color: #00557f;
          line-height: 27px;
          margin-top: 6px;
          text-align: left;
        }
      }
    }
  }

  .product-button {
    display: block;
    width: 187px;
    border: 1px solid #3388ff;
    border-radius: 5px;
    text-align: center;
    line-height: 49px;
    font-size: 17px;
    color: #3388ff;
    margin: auto;
  }

}

.fuwu {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.solution {
  width: 50%;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;

  .solution-item {
    width: 20%;
    height: 140px;
    box-shadow: 0px 3px 8px 2px rgba(138, 154, 206, 0.1);
    border-radius: 8px;
    padding-top: 30px;
    margin-right: 14px;
    margin-bottom: 14px;

    .itemTitle {
      text-align: center;
      font-size: 16px;
      color: #42464F;
      padding-top: 5px;
    }
  }

  .solution-item:hover {
    background: rgba(255, 255, 255, 0.35);
  }
}

.banner {
  .afterSales {
    position: relative;
    bottom: 60px;

    .afterSalesImg {
      display: inline;
      text-align: center;
      position: relative;
      top: 100px;
      z-index: 2;
    }

    .afterSales-bottom {
      background: url("../../public/shoubg.png");
      height: 280px;
      width: 550px;
      background-repeat: no-repeat;
      position: relative;
      top: 0;

      .content {
        margin: 12px 45px;
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        padding-top: 100px;

        .liaojie {
          color: #FFD7D4;
        }
      }

      .contents {
        margin: 0 45px;
        font-size: 15px;
        color: #ffffff;
        line-height: 28px;
        text-align: left;
        margin-top: 30px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }

}

.ad {
  height: 623px;
  width: 100%;
  padding-top: 70px;
  background: url("../../public/youbg.png") no-repeat;
  background-size: 100% 100%;

  .title {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }

  .advantage {
    display: flex;
    justify-content: center;
    align-items: center;

    .advantage-item {
      width: 220px;
      height: 332px;
      background: rgba(255, 255, 255, 0.35);
      border: 1px solid rgba(221, 221, 221, 1);
      box-shadow: 0px 0px 9px 1px rgba(224, 224, 224, 0.29);
      border-radius: 5px;
      margin: 0 10px;

      .advantage-img {
        padding-top: 45px;
      }

      .advantage-title {
        text-align: center;
        font-size: 22px;
        color: #00557f;
        font-weight: bold;
        padding-bottom: 30px;
        text-align: center;

      }

      .advantage-content {
        text-align: -webkit-center;

        .content {
          width: 150px;
          text-overflow: ellipsis;
        }
      }
    }

  }
}

.ca {
  padding: 70px 0;

  .title {
    font-size: 32px;
    line-height: 32px;
    text-align: center;
  }

  .case {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 50px;

    .case-item {
      width: 15%;
    }
  }
}

.new {
  background: url("../../public/fubg.png") no-repeat;
  background-size: 100% 100%;
  height: 300px;
  width: 100%;

  .title {
    font-size: 40px;
    color: #00557f;
    line-height: 40px;
    padding-top: 60px;
  }

  .title-content {
    font-size: 30px;
    color: #00557f;
    text-align: center;
    line-height: 30px;
    padding-top: 20px;
  }

  .title-button {
    width: 186px;
    line-height: 53px;
    background: #3388ff;
    display: inline-block;
    font-size: 19px;
    color: #fff;
    border-radius: 5px;
    margin-top: 50px;

    .title-button-item {
      display: inline-block;
      width: 100px;
      box-sizing: border-box;
      background: linear-gradient(
              180deg,
              rgba(1, 63, 156, 1.0),
              rgba(1, 63, 156, 1.0)
      );
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      color: #fff;
      border-radius: 18px;
    }
  }
}

.postion {
  position: absolute;
  top: 250px;
  left: 300px;
  color: #ffffff;
  width: 400px;
  text-align: left;

  .per {
    margin-bottom: 100px;
    display: inline-block;
    width: 180px;
    line-height: 48px;
    color: #3388ff;
    font-size: 20px;
    text-align: center;
    border: 1px solid #3388ff;
    border-radius: 4px;
    margin-top: 60px;
    border-color: #3388ff;
  }
}
</style>
