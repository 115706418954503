<template>
	<div>
		<div class="kbb_header">
			<div class="kbb-logo">
				<a href="/"><img src="../../public/kbblogo.png" alt="客宝宝"
						style="height: 65px;margin-top: 20px;width:100%;" /></a>
				<!-- <a href="/"><div class="logo-title">服务管理系统</div></a> -->
			</div>
			<div class="kbb-nav">
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
					active-text-color="#0664f5" router>
					<el-menu-item index="/" style="">首页</el-menu-item>
					<!-- <el-menu-item index="product" style="">产品</el-menu-item> -->
					<el-menu-item style="">
						<router-link to="/product">
							<div class="kbb-button-item">产品</div>
						</router-link>
					</el-menu-item>
					
					<!--          <el-submenu index="2">-->
					<!--            <template slot="title">产品</template>-->
					<!--            <el-menu-item index="2-1">售后管理系统</el-menu-item>-->
					<!--            <el-menu-item index="2-2">客户支持系统</el-menu-item>-->
					<!--            <el-menu-item index="2-3">移动服务APP</el-menu-item>-->
					<!--          </el-submenu>-->
					<!--          <el-submenu index="3">-->
					<!--            <template slot="title">解决方案</template>-->
					<!--            <el-menu-item index="3-1">机电设备</el-menu-item>-->
					<!--            <el-menu-item index="3-2">医疗器械</el-menu-item>-->
					<!--            <el-menu-item index="3-3">原厂售后</el-menu-item>-->
					<!--            <el-menu-item index="3-4">家用电器</el-menu-item>-->
					<!--            <el-menu-item index="3-5">仪器仪表</el-menu-item>-->
					<!--            <el-menu-item index="3-6">外包售后</el-menu-item>-->
					<!--            <el-menu-item index="3-7">智能制造</el-menu-item>-->
					<!--            <el-menu-item index="3-8">汽车车辆</el-menu-item>-->
					<!--            <el-menu-item index="3-9">服务商售后</el-menu-item>-->
					<!--            <el-menu-item index="3-10">IT运维</el-menu-item>-->
					<!--            <el-menu-item index="3-11">安防监控</el-menu-item>-->
					<!--            <el-menu-item index="3-12">内部维保</el-menu-item>-->
					<!--            <el-menu-item index="3-13">净水设备</el-menu-item>-->
					<!--            <el-menu-item index="3-14">手机数码</el-menu-item>-->
					<!--            <el-menu-item index="3-15">家装建材</el-menu-item>-->
					<!--            <el-menu-item index="3-16">生活服务</el-menu-item>-->
					<!--          </el-submenu>-->
					<!-- <el-menu-item index="SuccessfulCases" style="">成功案例</el-menu-item> -->
					<el-menu-item style="">
						<router-link to="/SuccessfulCases">
							<div class="kbb-button-item">成功案例</div>
						</router-link>
					</el-menu-item>
					
					<!-- <el-menu-item index="download" style="">下载</el-menu-item> -->
					<el-menu-item style="">
						<router-link to="/download">
							<div class="kbb-button-item">下载</div>
						</router-link>
					</el-menu-item>
					<!-- <el-menu-item index="productPrice" style="">价格</el-menu-item> -->

					<!--          <el-submenu index="productPrice">-->
					<!--            <template slot="title">价格</template>-->
					<!--            <el-menu-item index="productPrice">产品价格</el-menu-item>-->
					<!--            <el-menu-item index="6-2">定制开发</el-menu-item>-->
					<!--          </el-submenu>-->
					<!--          <el-submenu index="help">-->
					<!--            <template slot="title">帮助</template>-->
					<!--            <el-menu-item index="help">帮助中心</el-menu-item>-->
					<!--            <el-menu-item index="7-2">快速入门</el-menu-item>-->
					<!--            <el-menu-item index="7-3">功能介绍</el-menu-item>-->
					<!--            <el-menu-item index="7-4">视频教学</el-menu-item>-->
					<!--            <el-menu-item index="7-5">更新日志</el-menu-item>-->
					<!--            <el-menu-item index="7-6">开放平台</el-menu-item>-->
					<!--          </el-submenu>-->
					<!-- <el-menu-item index="about" style="">关于我们</el-menu-item> -->
					<el-menu-item style="">
						<router-link to="/about">
							<div class="kbb-button-item">关于我们</div>
						</router-link>
					</el-menu-item>
					<el-menu-item style="">服务热线：400-822-6635</el-menu-item>
					<!--          <el-submenu index="about">-->
					<!--            <template slot="title">关于</template>-->
					<!--            <el-menu-item index="about">关于我们</el-menu-item>-->
					<!--            <el-menu-item index="8-2">联系我们</el-menu-item>-->
					<!--            <el-menu-item index="8-3">新闻动态</el-menu-item>-->
					<!--            <el-menu-item index="8-4">代理加盟</el-menu-item>-->
					<!--          </el-submenu>-->
				</el-menu>
			</div>
			<div class="kbb-button">
				<router-link to="/register">
					<div class="kbb-button-item">立即免费体验</div>
				</router-link>
			</div>
			<div class="line"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "nav-header",
		data() {
			return {
				activeIndex: "1",
			};
		},
		methods: {
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},
		},
	};
</script>

<script>
	var _hmt = _hmt || [];
	(function() {
		var hm = document.createElement("script");
		hm.src = "https://hm.baidu.com/hm.js?5de734e0de2653613fcf56a88bad6d11";
		var s = document.getElementsByTagName("script")[0];
		s.parentNode.insertBefore(hm, s);
	})();
</script>




<style lang="scss" scoped>
	a {
		text-decoration: none
	}

	.kbb_header {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		line-height: 74px;
		text-align: left;
		// background: #fff;
		background: rgba(112,128,144, 0.5);
		background: #fff;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);

		.kbb-logo {
			float: left;
			padding-top: 20px;
			display: flex;
			align-items: center;
			width: 40%;
			height: 65px;
			z-index: 999;

			.logo-title {
				font-size: 56px;
				margin-top: 1px;
				height: 78px;
				color: #0664f5;
				font-weight: bold;
			}
		}

		.kbb-nav {
			width: 50%;
			float: left;
			justify-content: space-between;
			align-items: center;
			display: flex;
		}

		.main-nav {
			display: flex;
		}

		.kbb-button {
			margin-left: 20px;
			width: 5%;

			.kbb-button-item {
				display: inline-block;
				width: 100px;
				box-sizing: border-box;
				background: #3388ff !important;
				line-height: 36px;
				text-align: center;
				font-size: 14px;
				color: #fff;
				border-radius: 18px;
				// background: rgba(112, 128, 144, 0.5);
			}

			.kbb-button-item1 {
				margin-right: 20px;
				display: inline-block;
				width: 100px;
				box-sizing: border-box;
				line-height: 36px;
				text-align: center;
				font-size: 14px;
				color: #3388ff;
				border: 2px solid;
				border-radius: 18px;
			}
		}

		.el-menu {
			display: flex;
			align-items: flex-end;
			margin-bottom: 0px;
			background: none !important;
			border-bottom: none;
			font-weight: bolder;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			margin-left: 3px;

			>.el-menu-item {
				height: 30px;
				text-align: center;
				width: 110px;
				line-height: 30px;
				// border-radius: 18px;
				// border: 1px solid #01c2e8;
				margin-right: 1px;
				font-size: 16px;
				color: #555555;
			}

			>.el-menu-item.is-active {
				background-color: #1e80be !important;
				color: #fff !important;
				height: 33px !important;
				border-bottom: none;
			}

			.el-menu.el-menu--horizontal {
				border-bottom: none;
			}

			.el-menu-item:not(.is-disabled):hover {
				background-color: transparent;
			}
		}

		//add
		.el-menu--horizontal>.el-submenu .el-submenu__title,
		.el-menu--horizontal>.el-submenu.is-active .el-submenu__title,
		.el-submenu__title i {
			color: #fff;
		}

		.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
			// border-bottom: 2px solid #ff8923;
			color: #555555;
		}

		//鼠标悬浮时，子菜单的样式
		.el-menu-item:hover {
			outline: 0 !important;
			color: #3388ff !important;
			background: none !important;
		}

		// 一级菜单选中的样式
		.el-menu-item.is-active {
			color: #ddd !important;
			border-bottom-color: transparent !important;
		}

		// 二级菜单 选中的样式
		.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
			color: #fff !important;
			border-bottom-color: transparent !important;
		}

		//鼠标悬浮时，主菜单的样式
		.el-submenu__title:focus,
		.el-submenu__title:hover {
			outline: 0 !important;
			color: #fff !important;
			background: none !important;
		}

		.el-menu--horizontal:focus,
		.el-menu--horizontal:hover {
			outline: 0 !important;
			color: #fff !important;
			background: none !important;
		}

		.nav-menu-son .el-menu--horizontal:hover {
			background: pink !important;
		}

		.el-menu {
			background-color: none !important;
		}

		.el-menu-item a:hover {
			background: none;
		}

		.el-menu--popup-bottom-start {
			margin-top: -2px;
		}

		.el-menu--popup {
			background: none !important;
			padding: 0 0;
			min-width: 120px !important;
			line-height: 40px !important;
			box-shadow: 0 4px 8px 0 rgba(25, 14, 109, 0.13);
			border-radius: 5px;
		}

		.el-menu--horizontal .el-menu {
			background: none;
		}

		.el-menu--horizontal .el-menu .el-menu-item,
		.el-menu--horizontal .el-menu .el-submenu__title {
			background-color: rgba(255, 255, 255, 0.6);
			float: none;
			height: 36px;
			line-height: 36px;
			padding: 0 22px;
			color: #929292 !important;
			z-index: 100;
			font-weight: 500;
		}

		//二次菜单悬浮及背景样式
		.el-menu--popup-bottom-start .el-menu-item:hover {
			color: rgba(86, 86, 86, 1) !important;
		}

		.el-menu--popup-bottom-start .el-menu-item {
			background: #fff !important;
		}



	}
</style>
