<template>
	<div>
		<div class="main">
			<div class="side-bar">
				<a class="neo" style="background: transparent;text-align: center;line-height: 100%;border: none;">
					<img style="max-width: 100%;height: auto;" src="../../public/kefu/kefu_2.png">
				</a>
				<a id="custom_gt" class="icon-qq" @click="contactUs">在线客服 </a>
				<a class="icon-phone">电话咨询
					<div class="chat-tips-phone">
						<span class="title">销售热线：</span>
						<span class="phone">400-822-6635</span>
						<!--            <br>-->
						<!--            <span class="title">技术热线：</span>-->
						<!--            <span class="phone">400-822-6635</span>-->
					</div>
				</a>
				<a class="icon-wechat">微信咨询
					<div class="wechat-tips">
						<img src="../../public/kefu/kefu_qrCode.png" width="110">
						<p style="font-size: 14px;color: #333333;line-height: 1em;margin: 0;margin-top: 8px;">微信扫码 立即咨询
						</p>
					</div>
				</a>
				<a href="https://h5.kbbcloud.com/register" class="icon-marketing" target="_blank">免费试用</a>
				<a class="icon-top" id="gotop" style="position: fixed; cursor: pointer; display: none; right: 9px;"></a>
			</div>

			<div class="footer-one">
				<div class="footerImg">
					<a href="/"><img src="../../public/kbblogo.png" alt="" style="height:26px;margin-top: 4px;"></a>
					<!-- <div class="logo-title">服务管理系统</div> -->
				</div>
				<div class="footerTitle">售后服务管理系统</div>
				<div class="footerPhone">400-822-6635</div>
				<div class="footerEmail">kefu@kbbcloud.com</div>
				<div class="footerTime">周一至周五 9:00~18:00</div>
				<div class="footerTime">地址：深圳市南山区西丽街道清华信息港科研楼107</div>
			</div>
			<div class="footer-two">
				<div class="footerTwo-title">
					<router-link to="/product" class="linkStyle">
						行业解决方案
					</router-link>
				</div>
				<div class="footerTwo-classification">
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							机电设备
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							医疗器械
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							家用电器
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							仪器仪表
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							智能制造
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							汽车车辆
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							IT运维
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							安防监控
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							净水设备
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							手机数码
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							家装建材
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							生活服务
						</router-link>
					</div>
				</div>

			</div>
			<div class="footer-three">
				<div class="footerThree-title">
					<router-link to="/product" class="linkStyle">
						业务解决方案
					</router-link>
				</div>
				<div class="footerThree-classification">
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							原厂售后
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							外包售后
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							服务商售后
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							内部维保
						</router-link>
					</div>
				</div>
			</div>
			<div class="footer-four">
				<div class="footerFour-title">
					<router-link to="/product" class="linkStyle">
						核心产品
					</router-link>
				</div>
				<div class="footerFour-classification">
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							售后管理系统
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							售后服务APP
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/product" class="linkStyle">
							售后服务小程序
						</router-link>
					</div>
				</div>
			</div>
			<div class="footer-five">
				<div class="footerfive-title">
					<router-link to="/about" class="linkStyle">
						关于客宝宝
					</router-link>
				</div>
				<div class="footerFive-classification">

					<div class="classification-item">
						<router-link to="/about" class="linkStyle">
							<span classification-item>关于我们</span>
						</router-link>
					</div>

					<!--          <div class="classification-item">
			<router-link to="/about" class="linkStyle">
            联系我们
			</router-link>
          </div> -->
					<!--          <div class="classification-item">
			<router-link to="/#" class="linkStyle">
            新闻动态
			</router-link>
          </div> -->
					<div class="classification-item">
						<router-link to="/about" class="linkStyle">
							代理加盟
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/privacy" class="linkStyle">
							隐私政策
						</router-link>
					</div>
					<div class="classification-item">
						<router-link to="/UsageAgreement" class="linkStyle">
							使用协议
						</router-link>
					</div>
					<div class="classification-item">
						<p><a href="https://demo.kbbcloud.com/" class="linkStyle" target="_blank">用户登录</a></p>
					</div>
				</div>
			</div>
			<div class="footerSex">
				<div>
					<img src="../../public/erweima.jpg" alt="" style="height:180px">
				</div>
				<div class="footerSex-title">
					扫码关注微信公众号
				</div>
			</div>
		</div>
		<div class="copyright">
			<p>© 2021 深圳赛通信息科技有限公司 ALL RIGHTS RESERVED. <a href="https://beian.miit.gov.cn"
					target="_blank">粤ICP备18073260号</a></p>
		</div>

	</div>
</template>

<script>
	export default {
		name: "nav-footer",
		methods: {
			//  百度商桥
			contactUs() {
				window.open(
					'https://p.qiao.baidu.com/cps/chat?siteId=16254877&userId=27796517&siteToken=5de734e0de2653613fcf56a88bad6d11&cp=www.kbbcloud.com&cr=www.kbbcloud.com&cw=%E9%A6%96%E9%A1%B5%E6%B2%9F%E9%80%9A',
					'windowForBridgeIM',
					'left=500,top=200,width=750,height=600,location=no,resizable=yes,status=no,toolbar=no,menubar=no');
			},
		}
	}
</script>


<style lang="scss">
	.main {
		height: 388px;
		background: url("../../public/footerbg.png") no-repeat;
		background-size: 100% 100%;
		color: #B4B4B4;
		display: flex;
		padding: 30px;
		// align-items: center;
		justify-content: space-evenly;

		.footer-one {
			text-align: left;
			margin-top: 50px;

			.footerImg {
				width: 190px;
				height: 36px;
				display: flex;
				align-items: center;

				.logo-title {
					font-size: 17px;
					height: 19px;
					color: #fff;
					font-weight: bold;
				}
			}

			.footerTitle {
				color: #fff;
				font-size: 17px;
				line-height: 17px;
				padding-top: 19px;
			}

			.footerPhone {
				font-size: 28px;
				color: #fff;
				margin-top: 60px;
			}

			.footerEmail {
				font-size: 18px;
				color: #fff;
				margin-top: 16px;
				line-height: 18px;
			}

			.footerTime {
				font-size: 15px;
				line-height: 15px;
				color: #fff;
				margin-top: 20px;
			}
		}

		.footer-two {
			width: 175px;
			margin-top: 50px;

			.footerTwo-title {
				text-align: left;
				margin: 0;
				font-weight: bold;
				font-size: 16px;
				color: #fff;
				border-bottom: 1px solid #616A7D;
				padding-bottom: 10px;
			}

			.footerTwo-classification {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.classification-item {
					width: 49%;
					font-size: 14px;
					line-height: 40px;
				}
			}
		}

		.footer-three {
			width: 116px;
			margin-top: 50px;

			.footerThree-title {
				text-align: left;
				margin: 0;
				font-weight: bold;
				font-size: 16px;
				color: #fff;
				border-bottom: 1px solid #616A7D;
				padding-bottom: 10px;
			}

			.footerThree-classification {
				.classification-item {
					font-size: 14px;
					line-height: 40px;
				}
			}
		}

		.footer-four {
			width: 102px;
			margin-top: 50px;

			.footerFour-title {
				text-align: left;
				margin: 0;
				font-weight: bold;
				font-size: 16px;
				color: #fff;
				border-bottom: 1px solid #616A7D;
				padding-bottom: 10px;
			}

			.footerFour-classification {
				.classification-item {
					font-size: 14px;
					line-height: 40px;
				}
			}
		}

		.footer-five {
			width: 102px;
			margin-top: 50px;

			.footerfive-title {
				text-align: left;
				margin: 0;
				font-weight: bold;
				font-size: 16px;
				color: #fff;
				border-bottom: 1px solid #616A7D;
				padding-bottom: 10px;
			}

			.footerFive-classification {
				.classification-item {
					font-size: 14px;
					line-height: 40px;
				}
			}
		}

		.footerSex {
			margin-top: 50px;

			.footerSex-title {
				text-align: center;
				font-size: 16px;
				color: #fff;
				padding-top: 10px;
			}
		}

		.linkStyle {
			text-align: center;
			color: #fff;
			text-decoration: none;
		}
	}



	.side-bar {
		bottom: 120px;
		font-size: 12px;
		position: fixed;
		right: 9px;
		width: 60px;
		z-index: 9999 !important;
		height: 350px;

		a {
			display: block;
			border-radius: 0px;
			width: 70px;
			height: 70px;
			border-bottom: 1px solid #0056A0;
			color: #fff;
			line-height: 110px;
			text-align: center;
			background-repeat: no-repeat;
			background-size: 30px 30px;
			background-color: #0664F5;
			background-position: center 10px;
		}

		a:hover {
			background-color: #0450C4;
			color: #fff !important;
		}

		.icon-phone {}

		.icon-phone:hover .chat-tips-phone {
			visibility: visible;
			opacity: 1
		}

		.icon-phone .chat-tips-phone {
			line-height: 26px;
			transition: all 0.5s;
			-moz-transition: all 0.5s;
			-webkit-transition: all 0.5s;
			-o-transition: all 0.5s;
			visibility: hidden;
			opacity: 0;
			background-color: #fff;
			position: absolute;
			right: 60px;
			top: 140px;
			width: 320px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			padding: 10px 10px 6px 60px;
			box-sizing: border-box;
			color: #323232;
			font-size: 14px;
			background: #fff no-repeat 30px 30px left center;
			background: url(../../public/kefu/kefu_gray.png) no-repeat 10px center / 40px 40px;
			background-color: #fff;
			text-align: left;
		}

		.icon-phone:hover .chat-tips-phone {
			visibility: visible;
			opacity: 1
		}

		.chat-tips-phone .title {
			color: #0564F5;
			font-weight: 600;
			font-size: 18px;
		}

		.chat-tips-phone .phone {
			color: #0564F5;
			font-weight: 600;
			font-size: 18px;
		}

		.icon-qq {
			border-radius: 3px 0 0 0;
			display: block;
			background-image: url(../../public/kefu/kefu_online.png);
		}

		.icon-phone {
			background-image: url(../../public/kefu/kefu_phone.png);
		}

		.icon-wechat {
			background-image: url(../../public/kefu/kefu_wechart.png);
		}

		.icon-wechat:hover .wechat-tips {
			visibility: visible;
			opacity: 1;

		}

		.icon-marketing {
			border-radius: 0 0 0 3px;
			background-image: url(../../public/kefu/kefu_free.png);
		}

		.icon-top {
			background-image: url(../../public/kefu/kefu_top.png);
			border-radius: 0 0 0 4px;
			right: 0px !important;
			background-color: transparent;
			border: 0;
			background-size: 48px 47px;
		}

		.icon-top:hover {
			background-color: transparent;
		}

		.wechat-tips {
			visibility: hidden;
			opacity: 0;
			position: absolute;
			left: -140px;
			top: 120px;
			padding: 14px 12px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			background: #fff;
			text-align: center;
		}
	}




	.copyright {
		height: 60px;
		background: #1F2129;
		padding-top: 10px;
	}

	.copyright p {
		font-size: 14px;
		color: #B4B4B4;
		text-align: center;
	}

	.copyright p a {
		font-size: 14px;
		color: #B4B4B4;
		margin-right: 15px;
		text-decoration: none;
	}
</style>
