/* 封装 router */
//先导入vue
import Vue from 'vue'
import Router from 'vue-router'
// eslint-disable-next-line no-unused-vars
import Home from './pages/home'
import moHome from './pages/moHome'
import index from './pages/index'
Vue.use(Router) //use是加载插件固定的语法
//export是ES6的语法 通过它进行导出
export default new Router({
	//通过 export defalut 的方式导入一个对象 导入（ new Router）
	// 现在就是Router的语法
	mode: 'history',
	base: '/',
	routes: [
		//配置子路由 路由的列表等
		{
			path: '/',
			name: 'home',
			component: Home, //父路由  用于复用
			redirect: '/index', //添加重定向 默认情况下跳转到index里
			children: [{
					path: '/index',
					name: 'index',
					component: index,
					meta: {
						title: '客宝宝_服务管理系统_全渠道智能化服务管理平台|售后服务平台',						
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					}
				},
				{
					path: '/SuccessfulCases',
					name: 'SuccessfulCases',
					component: () => import('./pages/SuccessfulCases/SuccessfulCases.vue')
				},
				{
					path: '/download',
					name: 'download',
					component: () => import('./pages/download/download'),
					meta: {
						title: '下载_客宝宝_服务管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台',
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
				},
				{
					path: '/productPrice',
					name: 'productPrice',
					meta: {
						title: '价格_客宝宝_服务管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台',
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/price/productPrice')
				},
				{
					path: '/help',
					name: 'hfelp',
					meta: {
						title: '帮助中心_客宝宝_服务管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台',
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/help/index')
				},
				{
					path: '/help',
					name: 'hfelp',
					meta: {
						title: '帮助中心_客宝宝_服务管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台',
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/help/index')
				},
				{
					path: '/about',
					name: 'about',
					meta: {
						title: '关于我们_客宝宝_服务管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台',
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/about/aboutUs')
				},
				{
					path: '/privacy',
					name: 'privacy',
					meta: {
						title: '隐私政策_客宝宝_服务管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台',
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/about/privacy')
				},
				{
					path: '/usageAgreement',
					name: 'usageAgreement',
					meta: {
						title: '使用协议_客宝宝_服务管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台',
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/about/usageAgreement')
				},
				{
					path: '/product',
					name: 'product',
					meta: {
						title: '产品介绍_客宝宝_服务管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台',
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/product/afterSalesManagementSystem')
				},
				{
					path: '/register',
					name: 'register',
					meta: {
						title: '注册_客宝宝_服务管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台',
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/register')
				}

			]
		},
		{
			path: '/',
			name: 'moHome',
			component: moHome, //父路由  用于复用
			redirect: '/', //添加重定向 默认情况下跳转到index里
			children: [
				//移动端界面
				{
					path: '/mobileIndex',
					name: 'mobileIndex',
					meta: {
						title: "报修管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台",
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/mobile/mobileIndex.vue')
				},
				{
					path: '/mobileProduct',
					name: 'mobileProduct',
					meta: {
						title: "产品介绍_报修管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台",
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/mobile/mobileProduct.vue')
				},
				{
					path: '/mobileSuccessfulCases',
					name: 'mobileSuccessfulCases',
					meta: {
						title: "成功案例_报修管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台",
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/mobile/mobileSuccessfulCases.vue')
				},
				{
					path: '/mobileDownload',
					name: 'mobileDownload',
					meta: {
						title: "下载_报修管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台",
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/mobile/mobileDownload.vue')
				},
				{
					path: '/mobilePrice',
					name: 'mobilePrice',
					meta: {
						title: "价格_报修管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台",
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/mobile/mobilePrice.vue')
				},
				{
					path: '/mobileAbout',
					name: 'mobileAbout',
					meta: {
						title: "关于我们_报修管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台",
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/mobile/mobileAbout.vue')
				},
				{
					path: '/mobilePrivacy',
					name: 'mobilePrivacy',
					meta: {
						title: "隐私政策_报修管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台",
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/mobile/mobilePrivacy.vue')
				},
				{
					path: '/mobileUsageAgreement',
					name: 'mobileUsageAgreement',
					meta: {
						title: "使用协议_报修管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台",
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/mobile/mobileUsageAgreement.vue')
				},
				{
					path: '/mobileLogin',
					name: 'mobileLogin',
					meta: {
						title: "登录_报修管理系统_全渠道智能化报修管理平台|客宝宝|售后服务平台",
						content: {
							keywords: '客宝宝,报修管理系统,服务管理系统,服务云管家,客户服务小程序,客户服务系统,售后服务管理系统,SYTON,赛通信息,深圳赛通信息科技有限公司',
							description: '客宝宝_报修管理系统_服务管理系统_服务云管家_客户服务小程序_客户服务系统_售后服务管理系统_赛通信息_深圳赛通信息科技有限公司_SYTON',
						},
					},
					component: () => import('./pages/mobile/mobileLogin.vue')
				}
			]
		}
	]
})
