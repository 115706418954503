import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';  //引入axios
import VueAxios from 'vue-axios';  // 引入vue-axios
import App from './App.vue'
import router from "./router";
import Meta from 'vue-meta'
Vue.use(Meta)  //注册使用vue-meta模块
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAxios,axios);  //使用
router.beforeEach((to,from,next) =>{
	if(to.meta.title){
		document.title = to.meta.title
	}
	if(to.meta.content){
		let head =document.getElementsByTagName('head')
		let meta =document.getElementsByTagName('meta')
		console.log('head:',head)
		setTimeout(()=>{
			
			document.querySelector('meta[name="keywords"]').setAttribute('content',to.meta.content.keywords)
			document.querySelector('meta[name="description"]').setAttribute('content',to.meta.content.description)
			meta.content = to.meta.content
			head[0].appendChild(meta)
		},500)
	}
	next()
	window.scrollTo(0, 0)
})

// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面meta */
//   console.log(to.meta.content)
//   if(to.meta.content){
//     let head = document.getElementsByTagName('head');
//     let meta = document.createElement('meta');
// 	setTimeout(()=>{
// 		document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
// 		document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
// 		meta.content = to.meta.content;
// 		head[0].appendChild(meta)
// 	},500)
//   }
//   // /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
//   next()
// });


new Vue({
  router,
  render: h => h(App),
 mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
