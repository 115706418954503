<template>
	<div>
		<div class="header">
			<div><img src="../../public/kbblogo_m.png" alt="" class="logo" /></div>
			<a href="/" style="text-decoration:none;font-size: 25px;">
				<div class="logo-title">售后服务系统</div>
			</a>
			<div @click="drawers = true">
				<img src="../../public/menu2.png" alt="" />
			</div>
			<el-drawer title="我是标题" :visible.sync="drawers" :with-header="false" size="50%">
				<div style="text-align: right" @click="drawers = false">
					<i class="el-icon-close"></i>
				</div>
				<div class="menu">
					<div @click="drawers = false">
						<router-link to="/mobileIndex" class="linkStyle">
							首页
						</router-link>
					</div>
					<el-divider></el-divider>
					<div @click="drawers = false">
						<router-link to="/mobileProduct" class="linkStyle">
							产品
						</router-link>
					</div>
					<el-divider></el-divider>
					<div @click="drawers = false">
						<router-link to="/mobileSuccessfulCases" class="linkStyle">
							成功案例
						</router-link>
					</div>
					<el-divider></el-divider>
					<div @click="drawers = false">
						<router-link to="/mobileDownload" class="linkStyle">
							下载
						</router-link>
					</div>
					<el-divider></el-divider>
					<div @click="drawers = false">
						<router-link to="/mobilePrice" class="linkStyle">
							价格
						</router-link>
					</div>
					<el-divider></el-divider>
					<div @click="drawers = false">
						<router-link to="/mobileAbout" class="linkStyle">
							关于我们
						</router-link>
					</div>
					<el-divider></el-divider>
				</div>
				<div class="menu-login">
					<div @click="drawers = false">
						<!--            <router-link to="/mobileLogin" class="linkStyle">
              登录
            </router-link> -->
						<a href="https://demo.kbbcloud.com/" class="linkStyle" target="_blank">用户登录</a>
					</div>
					|
					<div @click="drawers = false">
						<router-link to="/mobileLogin" class="linkStyle">
							免费试用
						</router-link>
					</div>
				</div>
			</el-drawer>
		</div>
	</div>
</template>

<script>
	export default {
		name: "mo-header",
		data() {
			return {
				drawers: false, // 导航弹出
				activeIndex: "1",
			};
		},
		methods: {}
	};
</script>


<style lang="scss" scoped>
	* {
		padding: 0;
		margin: 0;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;

		.logo {
			width: 80px;
		}

		.icon {
			width: 10px;
			height: 10px;
			color: #909090;
		}
	}
	.logo-title{
		color: #2BCDA8;
		border-bottom: none;
		text-decoration:none;
		font-weight: bolder;
	}

	.menu {
		width: 300px;
		text-align: left;
		line-height: 50px;
		margin: 0 12px;
	}

	.menu-login {
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		text-align: center;
	}

	.linkStyle {
		text-align: center;
		color: #000000;
		text-decoration: none;
	}
</style>
